import { graphql, useStaticQuery } from 'gatsby'

export interface ProjectType {
  id: string
  frontmatter: {
    title: string
    layout: string
    date: string
    dateRangeStr: string
    thumbnail: string
    tags: string[]
    overview: string
    role: string[]
    liveUrl?: string
  }
  fields: {
    slug: string
  }
}

type Edges = {
  node: ProjectType
}[]

interface Projects {
  allMdx: {
    edges: Edges
  }
}

export const useProjects = (): Edges => {
  const data: Projects = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            layout: { eq: "project" }
            draft: { eq: false }
            hidden: { eq: false }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              layout
              title
              date(formatString: "MMM YYYY")
              dateRangeStr
              thumbnail
              tags
              role
              overview
              liveUrl
            }
          }
        }
      }
    }
  `)
  return data.allMdx.edges
}

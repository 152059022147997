import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Cell } from 'styled-css-grid'

import IndexLayout from '~/layouts'
import {
  SEO,
  Page,
  Container,
  Tag,
  Typography,
  Grid,
  ProjectCard,
} from '~/components'
import { SEOProps } from '~/components/SEO'
import { useProjects } from '~/hooks/useProjects'

const ProjectsPage = () => {
  const seo: SEOProps = {
    title: 'Work',
    path: '/projects',
    article: false,
  }
  const projects = useProjects()
  const theme = useContext(ThemeContext)
  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <Container>
          <Typography variant="h1">Work Archive</Typography>
          <Grid
            columns="repeat(auto-fit, minmax(257px, 1fr))"
            rowGap={`${theme.grid.gapLg}px`}
          >
            {projects.map(({ node }) => {
              const { slug } = node.fields
              const {
                date,
                dateRangeStr,
                title,
                thumbnail,
                tags,
              } = node.frontmatter

              return (
                <Cell key={slug}>
                  <ProjectCard
                    title={title}
                    slug={slug}
                    date={dateRangeStr || date}
                    image={thumbnail}
                    tags={
                      <>
                        {tags &&
                          tags.map((tag) => (
                            <Tag key={tag} to={tag}>
                              {tag}
                            </Tag>
                          ))}
                      </>
                    }
                  />
                </Cell>
              )
            })}
          </Grid>
        </Container>
      </Page>
    </IndexLayout>
  )
}

export default ProjectsPage
